import React from "react"
import "./banner.scss"
//import 'uikit/dist/css/uikit.css'


import BackgroundImage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"


const Banner = ({ Title, Subtitle, Background, Logo }) => {
    return (

        <BackgroundImage Tag="section" role="img" backgroundColor={"#0050a0"} className="" fluid={Background?.fluid || ["#0050a0"]}>
           
            <div className="banner-container">
                <div className="banner-content">
                    <div className="content-text">
                        {
                            Title ? <h2>{Title}</h2> : ""
                        }
                        {
                            Subtitle ? <p>{Subtitle}</p> : ""
                        }
                        <a href='https://www.google.com/search?q=tower+heating&oq=tower+heating&aqs=chrome..69i57j69i60l2j69i61j0l2.2737j0j7&sourceid=chrome&ie=UTF-8#lrd=0x487b3b19ea7c49f1:0xb595cfd32bf6ce6b,1,,'>
                            <GatsbyImage image={Logo.gatsbyImageData} alt="" className="item-image"></GatsbyImage>
                        </a>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )


}

export default Banner